// src/utils/imageCompressor.js
import imageCompression from 'browser-image-compression';

// Function to compress regular images
export const compressImage = async (imagePath) => {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], "compressed.png", { type: blob.type });

    const options = {
      maxSizeMB: 0.7,           // Maximum size in MB
      maxWidthOrHeight: 1024,   // Resize if larger
      useWebWorker: true,       // Use a web worker for performance
    };

    const compressedFile = await imageCompression(file, options);
    return URL.createObjectURL(compressedFile);
  } catch (error) {
    console.error("Image compression failed:", error);
    return imagePath; // Return the original image if compression fails
  }
};

// Function to compress background images
export const compressBackgroundImage = async (imagePath) => {
  try {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    const file = new File([blob], "compressed-bg.png", { type: blob.type });

    const options = {
      maxSizeMB: 1,            // Slightly higher max size for background images
      maxWidthOrHeight: 1920,  // Adjusted size for background images
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    return URL.createObjectURL(compressedFile);
  } catch (error) {
    console.error("Background image compression failed:", error);
    return imagePath; // Return the original image if compression fails
  }
};
