import React, { useEffect } from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import DataCloud from './pages/dataCloud';
import MarketingCloud from './pages/marketingCloud'
import About from './pages/about'
import Contact from './pages/contact';
import MarketingCloudPersonalization from './pages/marketingCloudPersonalization';
import AgentForce from './pages/agentForce';

// import Services from './pages/Services';
// import Solutions from './pages/Solutions';
// import AboutUs from './pages/AboutUs';
// import ContactUs from './pages/ContactUs';
import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './pages/common/header';
import Footer from './pages/common/footer'

import './App.css';
const App = () => {

  useEffect(() => {
    // Prevent duplicate GTM script
    if (!document.querySelector('script[data-gtm]')) {
      const scriptTag = document.createElement('script');
      scriptTag.setAttribute('data-gtm', 'true'); // marker
      scriptTag.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NXJSKDSV');
      `;
      document.head.appendChild(scriptTag);
    }
  
    // Prevent duplicate noscript iframe
    if (!document.querySelector('noscript[data-gtm]')) {
      const noscript = document.createElement('noscript');
      noscript.setAttribute('data-gtm', 'true'); // marker
      noscript.innerHTML = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NXJSKDSV"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
      document.body.insertBefore(noscript, document.body.firstChild);
    }
  }, []);
  

  return (
<>      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/data-cloud" element={<DataCloud />} />
        <Route path="/marketing-cloud" element={<MarketingCloud />} />
        <Route path="/marketing-cloud-personalization" element={<MarketingCloudPersonalization />} />
        <Route path="/agent-force" element={<AgentForce />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />

        {/* Catch-all route for non-existent pages */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer/>
      </>

   
  );
};

export default App;
