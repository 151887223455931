import React from 'react';
import Form from 'react-bootstrap/Form';

import Image from 'react-bootstrap/Image';
import shape3 from '../assets/images/layers/Layer_1.png'
import shape4 from '../assets/images/shape4.png'
import shape5 from '../assets/images/shape5.png'
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import clientOne from "../assets/images/clients/image 12.png";
import clientTwo from "../assets/images/clients/image 13.png";
import clientThree from "../assets/images/clients/image 14.png";
import clientFour from "../assets/images/clients/image 15.png";
import clientFive from "../assets/images/clients/image 16.png";
import clientSix from "../assets/images/clients/image 17.png";
import clientSeven from "../assets/images/clients/image 18.png";
import clientEight from "../assets/images/clients/image 19.png";
import clientNine from "../assets/images/clients/image 20.png";
import clientTen from "../assets/images/clients/image 21.png";
import clientEleven from "../assets/images/clients/image 22.png";
import clientTwelve from "../assets/images/clients/image 23.png";
import clientThirteen from "../assets/images/clients/image 24.png";
import clientFourteen from "../assets/images/clients/image 25.png";
import clientFifteen from "../assets/images/clients/image 26.png";
import clientSixteen from "../assets/images/clients/image 27.png";
import clientSeventeen from "../assets/images/clients/image 28.png";
import clientEightteen from "../assets/images/clients/image 29.png";
import CustomerSuccessImage from '../assets/images/Customer-360°-Success-image.png'

import DataCloudExpertsIcon from '../assets/images/icons/dataCloudExpertsIcon.png';
import DataConsultantsIcon from "../assets/images/icons/dataConsultants.png";
import inTheMartechIndustryIcon from '../assets/images/icons/inTheMartechIndustry.png';
import expertsIcon from '../assets/images/icons/experts.png';
import topPartnersIcon from '../assets/images/icons/topPartners.png';

import tataMotorsIcon from '../assets/images/brands/tata-motors.png';
import royalEnfieldIcon from '../assets/images/brands/royal-enfield.png';
import bibaIcon from '../assets/images/brands/biba.png';
import ambitFinvestIcon from '../assets/images/brands/ambit-finvest.png';
import lodhaIcon from '../assets/images/brands/lodha.png';
import theArtOfLivingIcon from '../assets/images/brands/the-art-of-living.png';
import dataAuditAndStrategyIcon from '../assets/images/icons/Data-Audit-and-Strategy.png';
import SegmentationAndActivationIcon from '../assets/images/icons/Segmentation-and-Activation .png';
import DataIngestionAndUnificationIcon from '../assets/images/icons/Data-Ingestion-and-Unification.png';
import CalculatedInsightsIcon from '../assets/images/icons/Calculated-Insights.png';
import CustomerViewIcon from '../assets/images/icons/Customer-View.png';
import GovernanceAndComplianceIcon from '../assets/images/icons/Governance-and-Compliance.png';
import quote from '../assets/images/icons/quote.png';
import DataColudAssessmentWorkshop from '../assets/images/Data-Colud-Assessment-workshop-two.png';
import PartnersForMeaningfulImpact from '../assets/images/partner-imapact-three.png'

import layerOne from '../assets/images/layers/GroupLeft.png';
import layerTwo from '../assets/images/layers/GroupRight.png';
import salesforceArrow from '../assets/images/salesforce-arrow.png';
import certifiedOne from '../assets/images/certified-one.png';

import dataCloudBannerMobile from '../assets/images/banner/data-cloud-banner-human-mobile.png';

const clientImages = [
    clientOne,
    clientTwo,
    clientThree,
    clientFour,
    clientFive,
    clientSix,
    clientSeven,
    clientEight,
    clientNine,
    clientTen,
    clientEleven,
    clientTwelve,
    clientThirteen,
    clientFourteen,
    clientFifteen,
    clientSixteen,
    clientSeventeen,
    clientEightteen
];

const DataCloud = () => {
    return (
        <>
            <header class="masthead subPageHead data-cloud-header dataCloudBanner">
                <div class="container">
                    <Row className='justify-content-start'>
                        <Col xl={7} lg={5}>
                            {/* <Image src={shape3} className='shape3 d-none d-md-block' /> */}
                            <div class="masthead-heading text-white">Salesforce  <span className='fancy-text4 ps-1 pe-2 mb-0  z-0 text-EE0000  position-relative overflow-hiddens text-white'>Data Cloud </span><br />Services</div>
                            <p className='mt-4 mb-0 text-white'>Unlock the Full Potential of Your Customer Data with <span className='text-uppercase fw-semibold'>Comsense</span></p>
                            {/* <Image src={shape4} className='shape4 d-none d-md-block' />
                            <Image src={shape5} className='shape5 d-none d-md-block' /> */}
                        </Col>
                        <Col lg={12} className='mt-5 mt-lg-0 d-block d-lg-none'>
                          <Image className='w-100' src={dataCloudBannerMobile}/>
                        </Col>
                    </Row>
                </div>
            </header>


            <section class="page-section bg-white pt-80 pb-80">
                <Container>
                    <Row className='align-items-center mb-3'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Turn Disconnected Data into  <span className='bordered-text'>Actionable</span>  Insights</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 text-center justify-content-center">
                        <Col lg={11} md={12} className="d-flex mb-2 mb-lg-0">
                            <p className="mt-0 fs-5 fw-normal ls-0">Your customers interact with your brand across multiple touchpoints—online, offline, in-store, and beyond. But without a <span className='fw-600'>unified, real-time data foundation</span>, you’re missing out on key opportunities for engagement, personalization, and revenue growth.</p>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section class="page-section bg-white">
                <Container className='bg-E2F1FE pt-80 ps-3 ps-lg-5 pb-4'>
                    <Row className='align-items-center mb-3'>
                        <Col col={12}>
                            <div class="d-flex align-items-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-3"><span className='text-dark'>Comsen<span className='text-ee0000'>se</span></span> + <span className='text-00A1E0'>Sales<span className='fst-italic'>f</span>orce</span> Data Cloud = Customer <span className='text-00A1E0 bordered-text bordered-text-w-100'>360°</span> Success</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4 align-items-top justify-content-between">
                        <Col lg={6} md={12} className="d-flex mb-2 mb-lg-0">
                            <p className="mt-0 fs-5 fw-normal mb-0 ls-0">As a <span className='text-dark fw-700'>trusted Salesforce partner</span>, we help enterprises like yours implement and optimize <span className='text-00A1E0 fw-700'>Salesforce Data Cloud</span> to break data silos, unify customer profiles and fuel AI-driven marketing, sales and service strategies. We’ll show you how your own data is the king of creating connected personalized customer experiences!</p>
                        </Col>
                        <Col lg={5} md={12} className="text-center text-lg-start">
                            <Image className='w-75 m-auto mt-3 mt-lg-0' src={CustomerSuccessImage} />
                        </Col>
                    </Row>

                </Container>
            </section>


            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-top mt-0 justify-content-center testimonials-area position-relative'>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12} className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={DataCloudExpertsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>25+</h5>
                                <p className='fw-500 fs-6'>Data Cloud Experts</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12} className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={DataConsultantsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>40+</h5>
                                <p className='fw-500 fs-6'>Data Consultants</p>
                            </div>
                        </Col>
                        <Col  xl={2} lg={2} md={4} sm={6} xs={12} className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={inTheMartechIndustryIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>10+ Years</h5>
                                <p className='fw-500 fs-6'>in the Martech Industry</p>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={4} sm={6} xs={12} className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={expertsIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>Multi Industry</h5>
                                <p className='fw-500 fs-6'>Expertise</p>
                            </div>
                        </Col>
                        <Col xl={2} lg={2} md={4} sm={6} xs={12} className='cus-col-sm text-center'>
                            <div className='mb-5 mb-lg-0 bg-transparent shadow-none'>
                                <div className='position-relative'>
                                    <Image width={70} className='m-auto' src={topPartnersIcon} />
                                </div>
                                <h5 className='mb-1 mt-4 fw-800 fs-4'>Top Partners</h5>
                                <p className='fw-500 fs-6'>of Salesforce</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-E2F1FE pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">Brands we have led to <span className='bordered-text'>Success</span> with Data Cloud</h2>
                            </div>
                        </Col>
                    </Row>

                    {/* <Row className="align-items-stretch mt-4">
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={150} src={tataMotorsIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={royalEnfieldIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={bibaIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={ambitFinvestIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={lodhaIcon} />
                            </div>
                        </Col>
                        <Col className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto' width={140} src={theArtOfLivingIcon} />
                            </div>
                        </Col>
                    </Row> */}


                    <Row className="align-items-stretch mt-4 justify-content-center">
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100'  style={{objectFit:'contain',  maxWidth:'100%'}} width={150} src={tataMotorsIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100'  style={{objectFit:'contain',  maxWidth:'100%'}} width={140} src={royalEnfieldIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100'  style={{objectFit:'contain',  maxWidth:'100%'}} width={140} src={bibaIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100 '  style={{objectFit:'contain',  maxWidth:'100%'}} width={140} src={ambitFinvestIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100'  style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={lodhaIcon} />
                            </div>
                        </Col>
                        <Col xs={6} sm={4} md={4} lg={3} xl={2} className="d-flex mb-3 mb-lg-0">
                            <div className="white-shadow-box text-center flex-fill d-flex flex-column justify-content-center">
                                <Image className='m-auto h-100'  style={{objectFit:'contain', maxWidth:'100%'}} width={140} src={theArtOfLivingIcon} />
                            </div>
                        </Col>
                    </Row>


                    {/* <Row className="align-items-stretch justify-content-center mt-0 row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
    {[
        tataMotorsIcon, royalEnfieldIcon, bibaIcon, ambitFinvestIcon, 
        lodhaIcon, theArtOfLivingIcon
    ].map((icon, index) => (
        <Col key={index} className="d-flex flex-column mb-4 overflow-hidden">
            <div style={{ height: '90px', maxWidth: '140px' }} className="white-shadow-box text-center d-flex flex-column justify-content-center align-items-center flex-grow-1 w-100">
                <Image className="m-auto h-100" style={{ maxWidth: '110px', objectFit: 'contain' }} src={icon} />
            </div>
        </Col>
    ))}
</Row> */}


                </Container>
            </section>
            <section class="page-section pt-80 pb-80 bg-white">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col col={12}>
                            <div class="text-center d-flex align-items-center justify-content-center">
                                <h2 class="section-heading section-heading-center fw-700 fs-2">How we help you succeed with <span className='bordered-text'>Salesforce</span> Data Cloud ?</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row className='align-items-center mt-4 justify-content-center position-relative'>
                        <Col lg={5} md={6}>
                            <div className='mb-4 mb-lg-4 d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={dataAuditAndStrategyIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Data Audit & Strategy</h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>We assess your data maturity, business objectives and use cases to craft a roadmap.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={SegmentationAndActivationIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Segmentation-and-Activation </h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Segment your audience with precision to deliver tailored, impactful experiences. </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={DataIngestionAndUnificationIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Data Ingestion & Unification</h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>We seamlessly integrate data from all sources and unify customer identities.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={CalculatedInsightsIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Calculated Insights</h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Discover customer journey patterns to increase engagement.</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={CustomerViewIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Customer 360 View </h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>Unlock a comprehensive, unified view of your customer across all touchpoints. </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5} md={6}>
                            <div className='mb-4 mb-lg-4  d-flex align-items-center'>
                                <div>
                                    <div className='blue-gradient-sqr-icon text-center position-relative'>
                                        <Image width={30} className='m-auto' src={GovernanceAndComplianceIcon} />
                                    </div>
                                </div>
                                <div className='content ms-4 pe-3'>
                                    <h5 className='mb-2 fw-600 fs-4 ls-minus-05'>Governance & Compliance</h5>
                                    <p className='fw-500 fs-6 mb-0 ls-minus-02 text-2C354CB2'>We ensure security, compliance and data governance best practices.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-E2F1FE pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col lg={10} className='offset-lg-1'>
                            <div class="text-center d-flex align-items-center justify-content-start">
                                <h2 class="section-heading section-heading-center fw-700 fs-2"><span className='bordered-text bordered-text-start'>Partners</span> for meaningful impact</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-stretch mt-4">
                        <Col lg={6} className="mb-3 mb-lg-0 offset-lg-1">
                            <Image className='mb-3' src={quote} alt='quote' width={30} />
                            <p className='fw-normal fs-6 mb-0 ls-0'>During project scoping phase, the whole team led by Umang interacted with all business owners along with us. Vishnu critically evaluated data needs for each use case and Wasim evaluated operational feasibility for all use cases. With their expertise and help, we could not only define use cases for first phase but also prioritize them depending on requirements. Vishnu and his team dedicatedly engaged with other stakeholders to resolve data related issues to enable use cases. Umang under Sushil's guidance has been a constant support for us to orchestrate the whole project.</p>
                            <div className='author mt-3'>
                                <h6 className='mb-1 text-252B42 fw-700'>- Senior Manager</h6>
                                <p className='ls-0 fs-10 text-252B42BF fw-500'>Digital Acquisition & Marketing Automation, Tata Motors</p>
                            </div>
                        </Col>
                        <Col lg={5}>
                            {/* <Image src={PartnersForMeaningfulImpact} className='w-100 mt-4 ms-4'/> */}
                            <div className='white-card box-shadow-five bg-white rounded-1 mt-4 ms-lg-4'>
                                <div className='d-flex mb-4 align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <Image src={certifiedOne} width={90} />
                                    </div>
                                </div>
                                <div className='d-flex mb-4 align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <p className='ls-0 fw-700 text-252B42 fs-18 mb-0'>Accelerators & Frameworks for Faster Time to Market</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='me-3'><Image src={salesforceArrow} width={8} /></div>
                                    <div>
                                        <p className='ls-0 fw-700 text-252B42 mb-0 fs-18'>Data is our Superpower - We Understand It, Love It, & Master It</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section class="page-section bg-EFFDFF pt-70 pb-70">
                <Container>
                    <Row className='align-items-center mb-5'>
                        <Col lg={10} className='offset-lg-1'>
                            <div class="text-center">
                                <span className='d-block fw-500 ls-0 mb-1 fs-15 text-252B42'>Every engagement follows a simple formula</span>
                                <h2 class="section-heading text-center mb-0 section-heading-center fw-700 fs-40">We listen. We plan. We execute. </h2>
                                <p className='text-252B42 ls-0 fw-500 fs-20'>Whichever engagement you choose we align with your goals, craft a clear roadmap, and get it done—while guiding you strategically every step of the way</p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-stretch mt-4">
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-white w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1">Strategy & Consultation</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 fs-11 flex-grow-1">
                                        We take the time to understand your business, aligning your goals with the right data, tools, and people to drive meaningful results.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="blue-box-back rounded-1  z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-blue-gradient w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1 text-white">Data Cloud Implementation</h3>
                                    <p className="text-left mb-0 ls-0 text-white fw-400 fs-11 flex-grow-1">
                                        Power personalized experiences by centralizing profiles and accounts into a single, reliable source of truth.
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-white w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold ls-0 mt-1">Managed Services</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 fs-11 flex-grow-1">
                                       Streamline data collection, cleansing, and unification at scale. 
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col className="mb-3 d-flex" lg={3} md={6}>
                            <div className="blue-box-back rounded-1 z-0 w-100 d-flex flex-column">
                                <div className="w8-bx-shadow z-1 bg-blue-gradient w8-box position-relative rounded-1 ps-3 pe-3 pt-3 pb-4 d-flex flex-column flex-grow-1">
                                    <h3 className="Fw-700 fs-16 fw-bold text-white ls-0 mt-1">Audit</h3>
                                    <p className="text-left mb-0 ls-0 fw-400 text-white fs-11 flex-grow-1">
                                    Power personalized experiences by centralizing profiles and accounts into a single, reliable source of truth. 
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>

            <section class="page-section bg-white pt-80 pb-80">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={12}>

                            <div className='bg-E2F1FE box-shadow-one rounded-4'>
                                <Row className='align-items-center'>
                                    <Col lg={7} md={12}>
                                        <div className="p-23">
                                            <span className='fancy-text2 ps-2 d-block mb-3 pe-3 z-0  position-relative overflow-hiddens fw-800 text-white fs-14'>Data Colud Assessment workshop</span>
                                            <h4 className='mb-3 pb-0 ps-6 fs-18'>Not sure how to get started and questioning weather Data Cloud will truly make an impact on your business?</h4>
                                            <p className='ls-minus036 mb-2 fs-6 fw-600 text-252B42'>Our 3-week in-depth analysis will assess your organization’s preparedness for a Data Cloud, our Data expert will help you:</p>
                                            <ul className='ps-2 mb-4 custom-ul'>
                                                <li className='ls-minus036 fs-12 fw-400'>Assess your organization's readiness</li>
                                                <li className='ls-minus036 fs-12 fw-400'>Understand your current Martech stack, Data & architecture</li>
                                                <li className='ls-minus036 fs-12 fw-400'>Identify top use cases to achieve with Data Cloud</li>
                                                <li className='ls-minus036 fs-12 fw-400'>Provide tailored roadmap & build an ROI-driven business case</li>
                                            </ul>
                                            <a class="btn btn-danger btn-xl me-3 ls-0 fw-600" href="#">Schedule Workshop</a>
                                        </div>
                                    </Col>
                                    <Col lg={5} md={12} className=''>
                                        <div className=''>
                                            <Image src={DataColudAssessmentWorkshop} className='w-100' />
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </Row>



                </Container>
            </section>

            <section class="page-section bg-E2F1FE z-0 overflow-hidden pt-70 pb-70 position-relative">
                <Image className='position-absolute z-n1 w-250 bg-cover h-auto position-absolute start-0 bottom-0' src={layerOne} />
                <Container>
                    <Row className='align-items-center justify-content-center'>
                        <Col lg={12}>
                            <div class="text-center">
                                <h2 class="text-252B42 fw-700 fs-26" style={{ lineHeight: 'normal' }}>Let’s Build Your Data-Driven Future
                                    <br />Get in Touch with our Experts Today!!</h2>

                                    <Nav.Link className="d-inline-block mt-4 text-white btn btn-danger btn-xl me-3 ls-0 fw-600" as={Link} to="../contact">Talk to our Expert</Nav.Link>
                                </div>
                        </Col>
                    </Row>
                </Container>
                <Image className='position-absolute z-n1 w-250 bg-cover h-auto position-absolute end-0 top-0 opacity-25' src={layerTwo} />
            </section>

        </>


    );
};

export default DataCloud;
